<template>

  <transition name="fade" mode="out-in">
    <div v-if="frage && messages && fragen" class="table-responsive">

      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">{{frage.bezeichnung}}</th>
            <th scope="col" class="text-center">{{messages['fh.umfrage.einfluss.SEHR_STARK']}}</th>
            <th scope="col" class="text-center">{{messages['fh.umfrage.einfluss.STARK']}}</th>
            <th scope="col" class="text-center">{{messages['fh.umfrage.einfluss.MITTELMAESSIG']}}</th>
            <th scope="col" class="text-center">{{messages['fh.umfrage.einfluss.KAUM']}}</th>
            <th scope="col" class="text-center">{{messages['fh.umfrage.einfluss.GAR_KEINEN']}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="singleFrage in fragen" v-bind:key="singleFrage.id">

            <!-- die Frage hat eine Anmerkung und keine Radios -->
            <td style="width: 60%" v-if="singleFrage.hasAnmerkung" colspan="6">
              <div class="form-group row" style="margin-right: 0">
                <label :for="'anmerkung_'+singleFrage.id" class="col-sm-2 col-form-label">{{singleFrage.bezeichnung}}</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" :id="'anmerkung_'+singleFrage.id" maxlength="255" v-model.trim="singleFrage.anmerkung" :disabled="readonly">
                </div>
              </div>
            </td>

            <!-- eine Frage mit Radios -->
            <td style="width: 60%" v-if="!singleFrage.hasAnmerkung">
              <span :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'">{{singleFrage.bezeichnung}}</span>
            </td>
            <td class="text-center" v-if="!singleFrage.hasAnmerkung">
              <div class="custom-control custom-radio">
                <input type="radio" :id="'frageOption1_'+singleFrage.id" :name="'frage_'+singleFrage.id" class="custom-control-input"
                       :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'" v-model="singleFrage.einflussAusmass" :value="'SEHR_STARK'" :disabled="readonly">
                <label class="custom-control-label" :for="'frageOption1_'+singleFrage.id"><span class="hidden">{{messages['fh.umfrage.einfluss.SEHR_STARK']}}</span></label>
              </div>
            </td>
            <td class="text-center" v-if="!singleFrage.hasAnmerkung">
              <div class="custom-control custom-radio">
                <input type="radio" :id="'frageOption2_'+singleFrage.id" :name="'frage_'+singleFrage.id" class="custom-control-input"
                       :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'" v-model="singleFrage.einflussAusmass" :value="'STARK'" :disabled="readonly">
                <label class="custom-control-label" :for="'frageOption2_'+singleFrage.id"><span class="hidden">{{messages['fh.umfrage.einfluss.STARK']}}</span></label>
              </div>
            </td>
            <td class="text-center" v-if="!singleFrage.hasAnmerkung">
              <div class="custom-control custom-radio">
                <input type="radio" :id="'frageOption3_'+singleFrage.id" :name="'frage_'+singleFrage.id" class="custom-control-input"
                       :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'" v-model="singleFrage.einflussAusmass" :value="'MITTELMAESSIG'" :disabled="readonly">
                <label class="custom-control-label" :for="'frageOption3_'+singleFrage.id"><span class="hidden">{{messages['fh.umfrage.einfluss.MITTELMAESSIG']}}</span></label>
              </div>
            </td>
            <td class="text-center" v-if="!singleFrage.hasAnmerkung">
              <div class="custom-control custom-radio">
                <input type="radio" :id="'frageOption4_'+singleFrage.id" :name="'frage_'+singleFrage.id" class="custom-control-input"
                       :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'" v-model="singleFrage.einflussAusmass" :value="'KAUM'" :disabled="readonly">
                <label class="custom-control-label" :for="'frageOption4_'+singleFrage.id"><span class="hidden">{{messages['fh.umfrage.einfluss.KAUM']}}</span></label>
              </div>
            </td>
            <td class="text-center" v-if="!singleFrage.hasAnmerkung">
              <div class="custom-control custom-radio">
                <input type="radio" :id="'frageOption5_'+singleFrage.id" :name="'frage_'+singleFrage.id" class="custom-control-input"
                       :class="singleFrage.einflussAusmass || anmerkungExists ? '' : 'is-invalid'" v-model="singleFrage.einflussAusmass" :value="'GAR_KEINEN'" :disabled="readonly">
                <label class="custom-control-label" :for="'frageOption5_'+singleFrage.id"><span class="hidden">{{messages['fh.umfrage.einfluss.GAR_KEINEN']}}</span></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </transition>

</template>
<style scoped>
  span.is-invalid {
    color: #ff4136;
  }
  .custom-radio {
    padding-left: 1em;
    margin-left: 50%;
    margin-right: 50%;
  }
</style>
<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';

  export default {

    name: 'frage-table',
    props: {
      /** anzuzeigende Frage mit Fragen */
      frage: {
        type: Object,
        required: false
      },
      fragen: {
        type: Array,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        messages: null
      }
    },
    watch: {

      fragen: function () {
        this.emitEinfluss();
      }

    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    computed: {

      isValid: function () {
        return true;
      },
      /** wenn eine Anmerkung bei Andere eingegeben wurde,
       * dann können alle anderen Felder als valide angezeigt
       * werden
       */
      anmerkungExists: function () {

          for (var i = 0; i < this.fragen.length; i++) {
              if (this.fragen[i].hasAnmerkung && this.fragen[i].anmerkung && this.fragen[i].anmerkung.length > 2) {
                  return true;
              }
          }
          return false;

      }

    },
    methods: {

      /** Empfehlungs-Objekt zurückschreiben */
      emitEinfluss: function () {

        var respon = {
          frage:  this.frage,
          fragen: this.fragen
        };

        this.$emit('set-frage', respon);

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.umfrage.einfluss.SEHR_STARK',
          'fh.umfrage.einfluss.STARK',
          'fh.umfrage.einfluss.MITTELMAESSIG',
          'fh.umfrage.einfluss.KAUM',
          'fh.umfrage.einfluss.GAR_KEINEN'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }
</script>
