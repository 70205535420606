import { render, staticRenderFns } from "./SelectVorbildungTree.vue?vue&type=template&id=73854ff0"
import script from "./SelectVorbildungTree.vue?vue&type=script&lang=js"
export * from "./SelectVorbildungTree.vue?vue&type=script&lang=js"
import style0 from "./SelectVorbildungTree.vue?vue&type=style&index=0&id=73854ff0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports