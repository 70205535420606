<template>

  <form @submit="checkForm" name="reifeForm" id="reifeForm">
    <transition name="fade" mode="out-in">

      <div  v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'Reife'"></nav-tabs>

        <h2 v-html="messages['fh.nav.universitaetsreife']"></h2>

        <hr class="mt-5 mb-5">

        <!-- Fehlermeldung ausgeben falls noch keine Studiengänge gewählt wurden -->
        <div v-if="!studiengaenge || !studiengaenge.hauptStudiengang">
          <error-message :errormessage="messages['fh.studiengang.select']" />
        </div>
        <div v-else>

          <transition name="fade" mode="out-in">
            <div v-if="bewerbergruppe === 'INCOMING' && isMasterstudium">

              <h3>{{messages['fh.studiengang.incoming.vorhigesStudium']}}</h3>

              <div class="form-group row">
                <div class="col-md-2">
                  <label for="vorherigeUniversitaet" class="col-form-label">{{ messages['fh.userData.incomings.vorherigeUniversitaet'] }}*</label>
                </div>
                <div class="col-md-10">
                  <input type="text"
                         maxlength="200"
                         class="form-control"
                         v-bind:class="{ 'is-invalid': !isVorherigeUniversitaetValid }"
                         name="vorherigeUniversitaet"
                         id="vorherigeUniversitaet"
                         :placeholder="messages['fh.userData.incomings.vorherigeUniversitaet']"
                         v-model.trim="vorherigeUniversitaet"
                         required
                         :readonly="abgeschlossen">
                  <div class="invalid-feedback">
                    {{ messages['fh.userData.incomings.vorherigeUniversitaet.insert'] }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-2">
                  <label for="vorherigerBereich" class="col-form-label">{{ messages['fh.userData.incomings.vorherigerBereich'] }}*</label>
                </div>
                <div class="col-md-10">
                  <input type="text"
                         maxlength="200"
                         class="form-control"
                         v-bind:class="{ 'is-invalid': !isVorherigerBereichValid }"
                         name="vorherigerBereich"
                         id="vorherigerBereich"
                         :placeholder="messages['fh.userData.incomings.vorherigerBereich']"
                         v-model.trim="vorherigerBereich"
                         required
                         :readonly="abgeschlossen">
                  <div class="invalid-feedback">
                    {{ messages['fh.userData.incomings.vorherigerBereich.insert'] }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-2">
                  <label for="vorherigerAbschluss" class="col-form-label">{{ messages['fh.userData.incomings.vorherigerAbschluss'] }}*</label>
                </div>
                <div class="col-md-10">
                  <utilo-date-picker
                    :datum="vorherigerAbschluss"
                    v-on:input="setVorherigerAbschluss"
                    id="vorherigerAbschluss"
                    class="form-control"
                    v-bind:class="{ 'is-invalid': !isVorherigerAbschlussValid }"
                    name="vorherigerAbschluss"
                    v-if="sprache && !abgeschlossen">
                  </utilo-date-picker>
                  <readonly-date-input v-else :date="vorherigerAbschluss" />
                  <div class="invalid-feedback date-picker">
                    {{ messages['fh.userData.incomings.vorherigerAbschluss.insert'] }}
                  </div>
                </div>

              </div>

            </div><!-- end incoming und master -->
          </transition>

          <transition name="fade" mode="out-in">
            <div v-if="bewerbergruppe && bewerbergruppe === 'STUDENT'">

              <h2>{{ messages['fh.reife'] }}</h2>

              <!-- Universitätsreife -->
              <select-land-and-ort :land="staat"
                                   :land-label="messages['fh.reife.staat']"
                                   :land-error-message="messages['fh.reife.staat.invalid']"
                                   :readonly="abgeschlossen"
                                   :ort="ort"
                                   :ort-label="messages['fh.reife.vorbildung.ort']"
                                   :ort-error-message="messages['fh.reife.vorbildung.ort.insert']"
                                   :ortobj="ortobj"
                                   v-on:set-land-and-ort="setLandAndOrt"
              />

              <!-- zuerst den Staat wählen und dann die Vorbildung dazu, da diese vom Staat abhängig ist -->
              <transition name="fade" mode="out-in">
                <div v-if="staat && ort">
                  <div class="form-group row">
                    <div class="col-md-2">
                      <label for="vorbildung" class="col-form-label">{{ messages['fh.reife.vorbildung'] }}*</label>
                    </div>
                    <div class="col-md-10">

                      <!-- Clustering Zugangsvoraussetzungen -->
                      <select-vorbildung-tree :invalid-message="messages['fh.reife.vorbildung.insert']"
                                              :vorbildung="vorbildung"
                                              id="vorbildung"
                                              v-on:set-vorbildung="setVorbildung"
                                              :staat="staat"
                                              :readonly="abgeschlossen"
                      />

                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-2">
                      <label for="reifepruefungDatum" class="col-form-label">{{ messages['fh.reife.reifepruefung.datum'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <utilo-date-picker
                        :datum="reifepruefungDatum"
                        id="reifepruefungDatum"
                        class="form-control"
                        v-bind:class="{ 'is-invalid': !isReifepruefungDatumValid }"
                        name="reifepruefungDatum"
                        v-on:input="setReifepruefungDatum"
                        :language="sprache"
                        v-if="sprache && !abgeschlossen">
                      </utilo-date-picker>
                      <readonly-date-input v-else :date="reifepruefungDatum"></readonly-date-input>
                      <div class="invalid-feedback date-picker">
                        {{ messages['fh.reife.reifepruefung.datum.insert'] }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>

              <hr class="mt-5 mb-5">

              <!-- Masterreife -->
              <div v-if="isMasterstudium">

                <h2>{{messages['fh.reife.masterreife']}}</h2>

                <select-land-and-ort :land="staatMasterreife"
                                     :land-error-message="messages['fh.reife.staatMasterreife.invalid']"
                                     :land-label="messages['fh.reife.staatMasterreife']"
                                     :readonly="masterAbgeschlossen"
                                     :ort="ortMasterreife"
                                     :ort-label="messages['fh.reife.vorbildung.ort']"
                                     :ort-error-message="messages['fh.reife.vorbildung.ort.insert.master']"
                                     :ortobj="ortobjMasterreife"
                                     v-on:set-land-and-ort="setLandAndOrtMasterreife">
                </select-land-and-ort>

                <transition name="fade" mode="out-in">
                  <div v-if="staatMasterreife && ortMasterreife">
                    <div class="form-group row">
                      <div class="col-md-2">
                        <label for="masterreifeVorbildung" class="col-form-label">{{ messages['fh.reife.masterreifeVorbildungen'] }}*</label>
                      </div>
                      <div class="col-md-10">

                        <!-- Clustering Zugangsvoraussetzungen -->
                        <select-vorbildung-tree :invalid-message="messages['fh.reife.vorbildung.insert.master']"
                                                :vorbildung="masterreifeVorbildung"
                                                id="masterreifeVorbildung"
                                                v-on:set-vorbildung="setMasterreifeVorbildung"
                                                :staat="staatMasterreife"
                                                :is-for-master="true"
                                                :readonly="masterAbgeschlossen"
                        />

                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-2">
                        <label for="masterreifeDatum" class="col-form-label">{{ messages['fh.reife.masterreifeDatum'] }}*</label>
                      </div>
                      <div class="col-md-10">
                        <utilo-date-picker
                          :datum="masterreifeDatum"
                          id="masterreifeDatum"
                          v-on:input="setMasterreifeDatum"
                          class="form-control"
                          v-if="sprache && !masterAbgeschlossen"
                          v-bind:class="{ 'is-invalid': !isMasterreifeDatumValid }"
                          name="masterreifeDatum">
                        </utilo-date-picker>
                        <readonly-date-input v-else :date="masterreifeDatum"></readonly-date-input>
                        <div class="invalid-feedback date-picker">
                          {{messages['fh.reife.masterreifeDatum.insert']}}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>

                <hr class="mt-5 mb-5">

              </div>

            </div><!-- end if bewerbergruppe STUDENT -->
          </transition>

          <transition name="fade" mode="out-in">
            <div v-if="bewerbergruppe && bewerbergruppe === 'AUSSERORDENTLICHER_HOERER'">

              <h2>{{ messages['fh.reife.hoechsteSchulbildung'] }}</h2>

              <!-- Schulbildungen -->
              <div class="form-group row">

                <div class="col-md-2">
                  <label for="schulbildungen" class="col-form-label">{{ messages['fh.reife.schulbildung'] }}</label>
                </div>
                <div class="col-md-10">

                  <div class="col-md-12">
                    <insert-schulbildung :schulbildung="schulbildung"
                                         v-on:set-schulbildung="setSchulbildung"
                                         :readonly="abgeschlossen">
                    </insert-schulbildung>
                  </div>

                </div>

              </div>

            </div>
          </transition>

          <div class="form-group row">
            <div class="col-md-10 offset-col-md-2">
              <button type="submit"
                      v-if="!abgeschlossen"
                      class="button primary"
                      :disabled="!formValid">{{ messages['default.button.forward.label'] }}</button>
              <!-- zeige einen Speichern Button falls im Hinhinein eine Master Reife hinzugefügt werden muss -->
              <button type="button"
                      v-if="showMasterreifeSaveButton"
                      class="button primary red"
                      v-on:click="saveMasterreife()"
                      :disabled="!masterValid">{{messages['fh.reife.master.save']}}</button>
              <button type="button"
                      v-on:click="next()"
                      v-else-if="abgeschlossen"
                      class="button primary">{{messages['default.button.next.label']}}</button>
            </div>
          </div>

        </div> <!-- else studiengänge vorhanden -->

      </div>

    </transition>

  </form>

</template>

<script>

import axios                from 'axios';
import NavTabs              from './layout/NavTabs.vue';
import SelectLandAndOrt     from './user/SelectLandAndOrt.vue';
import moment               from 'moment';
import UtiloDatePicker      from './layout/UtiloDatePicker.vue';
import insertSchulbildung   from  './reife/InsertSchulbildung.vue';
import CheckLogin           from './login/CheckLogin.vue';
import ReadonlyDateInput    from './layout/ReadonlyDateInput.vue';
// Import the EventBus.
import { EventBus }         from '../event-bus.js';
import bootbox              from 'bootbox';
import selectVorbildungTree from '@/components/reife/SelectVorbildungTree.vue';
import ErrorMessage from "@/components/layout/ErrorMessage.vue";

export default {

  name: 'Reife',
  data () {
    return {
      messages: null,
      /** Staat in dem die Universitätsreife abgelegt wurde */
      staat: null,
      ort: null,
      ortobj: null,
      /** Reifeprufung abgelegt am */
      reifepruefungDatum: null,
      /** Schulbildung */
      schulbildung: null,
      staatMasterreife: null,
      ortMasterreife: null,
      ortobjMasterreife: null,
      masterreifeDatum: null,
      /** zeigt die Felder für ein Masterstudium an oder nicht */
      isMasterstudium: null,
      bewerbergruppe: null,
      vorbildung: null,
      masterreifeVorbildung: null,
      vorherigeUniversitaet: null,
      vorherigerBereich: null,
      vorherigerAbschluss: null,
      abgeschlossen:null,
      /** Sprache des Datepicker */
      sprache: null,
      /** falls eine Masterreife im nachhinein hinzugefügt wird,
       * werden die Felden so lange nicht dispabled angezeigt,
       * so lange die Änderungen nicht gespeichert wurden
       */
      isMasterreifeSaved: false,
      /** ausgewählte Studiengänge des Bewerbers */
      studiengaenge: null,
    }
  },
  mounted: function () {

    this.init();

  },
  components: {
    ErrorMessage,

    NavTabs,
    SelectLandAndOrt,
    UtiloDatePicker,
    insertSchulbildung,
    CheckLogin,
    selectVorbildungTree,
    ReadonlyDateInput

  },
  computed: {

    /**
     * falls nachträglich eine Masterreife gespeichert werden
     * muss einen Button dafür anzeigen
     */
    showMasterreifeSaveButton: function() {

        if (this.abgeschlossen && !this.isMasterreifeSaved) {
          return true;
        }
        return false;

    },
    /** sind alle Felder für die Masterreife ausgefüllt worden */
    masterValid: function() {

        if (!this.masterreifeDatum || !this.masterreifeVorbildung || !this.staatMasterreife) {
            return false;
        }
        return true;

    },
    /**
     * prüft ob abgeschlossen und alle Master Felder ausgefüllt wurden
     */
    masterAbgeschlossen: function() {

        if (!this.abgeschlossen || !this.isMasterreifeSaved) {
            return false;
        } else {

          // wenn noch nicht alles ausgefüllt wurde, Felder noch anzeigen:
          if (!this.masterreifeDatum || !this.masterreifeVorbildung || !this.staatMasterreife) {
              return false;
          }

        }

        return true;

    },
    /** prüft ob die Studienrichtung korrekt eingegeben wurde */
    isStudienrichtungValid: function() {

      if ((this.instutition && this.instutition !== '') || (this.abschlussdatum && this.abschlussdatum !== '')) {
        // Studienrichtung muss eingegeben werden:
        if (!this.studienrichtung || this.studienrichtung.length < 3) {
          return false;
        }
      }

      // es ist eh nichts erfasst:
      return true;

    },
    /** prüft ob die Institution korrekt eingegeben wurde */
    isInstitutionValid: function() {

      if ((this.studienrichtung && this.studienrichtung !== '') || (this.abschlussdatum && this.abschlussdatum !== '')) {
        // Studienrichtung muss eingegeben werden:
        if (!this.instutition || this.instutition.length < 3) {
          return false;
        }
      }

      // es ist eh nichts erfasst:
      return true;

    },
    /** prüft ob das Abschlußdatum korrekt eingegeben wurde */
    isAbschlussdatumValid: function() {

      // Datum muss eingegeben werden:
      if (!this.abschlussdatum) {
        return false;
      }

      var DATE_FORMAT = 'YYYY-MM-DD';
      if (moment(this.abschlussdatum, DATE_FORMAT).format(DATE_FORMAT) !== this.abschlussdatum) {
        console.log('Abschlussdatum ist nicht valide: ' + this.abschlussdatum);
        return false;
      }

      return true;

    },
    isMasterreifeDatumValid: function() {

      if (!this.masterreifeDatum) {
        return false;
      }

      var DATE_FORMAT = 'YYYY-MM-DD';
      if (moment(this.masterreifeDatum, DATE_FORMAT).format(DATE_FORMAT) !== this.masterreifeDatum) {
        return false;
      }

      return true;

    },
    isVorbildungValid: function() {

      if (!this.vorbildung) return false;
      return true;

    },
    isVorherigerAbschlussValid: function () {

      if (this.bewerbergruppe !== 'INCOMING' || !this.isMasterstudium) return true;

      if (!this.vorherigerAbschluss) {
        return false;
      }

      return true;

    },
    isVorherigerBereichValid: function () {

      if (this.bewerbergruppe !== 'INCOMING' || !this.isMasterstudium) return true;

      if (!this.vorherigerBereich || this.vorherigerBereich === '' || this.vorherigerBereich.length < 2) {
        return false;
      }

      return true;

    },
    isVorherigeUniversitaetValid: function () {

      if (this.bewerbergruppe !== 'INCOMING' || !this.isMasterstudium) return true;

      if (!this.vorherigeUniversitaet || this.vorherigeUniversitaet === '' || this.vorherigeUniversitaet.length < 2) {
        return false;
      }

      return true;

    },
    isSchulbildungValid: function() {

      // bei einem AO Hörer ist die höchste Abgeschlossene Schulbildung ein Pflichtfeld
      if (this.bewerbergruppe === 'STUDENT' || this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {

        if (!this.schulbildung) {
          console.log('Schulbildung noch nicht vorhanden');
          return false;
        }

        if (!this.schulbildung.abschluss || !this.schulbildung.bis || !this.schulbildung.land || !this.schulbildung.ort || !this.schulbildung.schule || !this.schulbildung.von) {
          console.log('Schulbildung Parameter noch nicht vorhanden');
          return false;
        }

      }

      return true;

    },
    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      // Incoming prüfen
      if (this.bewerbergruppe === 'INCOMING' && this.isMasterstudium) {

        if (!this.isVorherigeUniversitaetValid ||
          !this.isVorherigerAbschlussValid ||
          !this.isVorherigerBereichValid) {
          return false;
        } else {
          return true;
        }

      }

      // bei einem Masterstudium brauche ich die Masterreife:
      if (this.isMasterstudium) {

        if (!this.masterreifeVorbildung || !this.isMasterreifeDatumValid || !this.isMasterreifeDatumValid) {
          return false;
        }
        // Orte der Masterreife valide?
        if (!this.staatMasterreife) return false;
        if (!this.ortMasterreife && !this.ortobjMasterreife) return false;

      }

      if (this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {

        // ein AO braucht nur die höchst abgeschlossene Schulbildung, das ist bei dem ein Pflichtfeld
        if (this.isSchulbildungValid) {
          return true;
        }

      }

      if (
        this.staat &&
        (this.ort || this.ortobj) &&
        this.isVorbildungValid &&
        this.isReifepruefungDatumValid
      ) {
        return true;
      }

      return false;

    },
    /** Prüft das Reifeprüfung abgelegt am Datum */
    isReifepruefungDatumValid: function () {

      if (!this.reifepruefungDatum) return false;

      var DATE_FORMAT = 'YYYY-MM-DD';
      if (moment(this.reifepruefungDatum, DATE_FORMAT).format(DATE_FORMAT) !== this.reifepruefungDatum) {
        console.log('Reifeprüfund Datum ist nicht valide: ' + this.reifepruefungDatum);
        return false;
      }

      return true;

    }

  },
  methods: {

    setMasterreifeDatum: function (val) {
      this.masterreifeDatum = val;
    },
    setVorherigerAbschluss: function (val) {
      this.vorherigerAbschluss = val;
    },
    setReifepruefungDatum: function (val) {
      this.reifepruefungDatum = val;
    },
    setMasterreifeVorbildung: function (val) {
      this.masterreifeVorbildung = val;
    },
    setVorbildung: function (val) {
      this.vorbildung = val;
    },
    setLandAndOrtMasterreife: function (obj) {

      // hat sich der Staat geändert, dann auch die Masterreife Vorbildung löschen:
      if (obj && obj.land && this.staatMasterreife && this.staatMasterreife.id !== obj.land.id) {
        this.masterreifeVorbildung = null;
      }
      this.staatMasterreife   = obj.land;
      this.ortMasterreife     = obj.ort;
      this.ortobjMasterreife  = obj.ortobj;

    },
    setSchulbildung: function (obj) {

      console.log('neue Schulbildung hinzugefügt: ' + obj.von);

      if (obj.von) {
        this.schulbildung = obj;
      } else {
        this.schulbildung = null;
      }

    },
    setLandAndOrt: function (obj) {

      // hat sich der Staat geändert, dann auch die Vorbildung löschen:
      if (obj && obj.land && this.staat && this.staat.id !== obj.land.id) {
        this.vorbildung = null;
      }
      this.staat  = obj.land;
      this.ort    = obj.ort;
      this.ortobj = obj.ortobj;

    },
    init: function () {

      this.fetchMessages();
      this.getUniversitaetsreife();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

      var that = this;

      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(function (res) {
        that.abgeschlossen = res;
      });

      this.getBewerbergruppe(function (result) {

        that.bewerbergruppe = result;
        // kann dieser Menüpunkt übersprungen werden?
        that.checkIfVorbildung();

      });
      this.checkIfMasterstudium(function (result) {

        that.isMasterstudium = result;
        // kann dieser Menüpunkt übersprungen werden?
        that.checkIfVorbildung();

      });
      // hole Studiengänge des Bewerbers
      var url    = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then( response => {

        that.studiengaenge = response.data;

      })

    },
    /** bei einem Incoming ohne Masterstudium kann dieser Menüpunkt übersprungen werden */
    checkIfVorbildung: function () {

      if (this.isMasterstudium === false && this.bewerbergruppe === 'INCOMING') {
        this.$router.push('Upload');
      }

    },
    /** eine bestehende Universitätsreife lesen */
    getUniversitaetsreife: function () {

      var that = this;
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      var url = process.env.VUE_APP_SERVER_URL + '/universitaetsreife';
      axios({
        method: 'get',
        url: url,
        params: params,
        withCredentials: true
      }).then(function (daten) {

          if (daten && daten.data && daten.data.universitaetsreife && daten.data.universitaetsreife.id) {

            console.log('setze Daten der Reife: ' + daten.data);

            that.staat                  = daten.data.staat;
            that.ort                    = daten.data.ort;
            that.ortobj                 = daten.data.ortobj;
            that.reifepruefungDatum     = daten.data.universitaetsreife.universitaetsreifeDatum;
            if (that.reifepruefungDatum) {
              that.reifepruefungDatum   = that.javaDateToMoment(that.reifepruefungDatum).format('YYYY-MM-DD') // that.javaDateToHtml5Date(that.reifepruefungDatum);
            }

            that.staatMasterreife       = daten.data.staatMasterreife;
            that.ortMasterreife         = daten.data.ortMasterreife;
            that.ortobjMasterreife      = daten.data.ortobjMasterreife;
            that.masterreifeDatum       = daten.data.universitaetsreife.masterreifeDatum;
            that.masterreifeVorbildung  = daten.data.masterreifeVorbildung;
            if (that.masterreifeDatum) {
              that.masterreifeDatum     = that.javaDateToMoment(that.masterreifeDatum).format('YYYY-MM-DD') // that.javaDateToHtml5Date(that.masterreifeDatum);
            }
            // Masterreife wurde schon gespeichert falls alle Werte vorhanden sind:
            if (that.staatMasterreife && that.masterreifeDatum && that.masterreifeVorbildung) {
                that.isMasterreifeSaved = true;
            }

            that.schulbildung           = daten.data.schulbildung;
            that.freigegenstaendeList   = daten.data.freigegenstaende ? daten.data.freigegenstaende : [];

            that.studienrichtung        = daten.data.studienberechtigung ? daten.data.studienberechtigung.studienrichtung : null;
            that.instutition            = daten.data.studienberechtigung ? daten.data.studienberechtigung.instutition : null;
            that.abschlussdatum         = daten.data.studienberechtigung ? daten.data.studienberechtigung.abschlussdatum : null;
            if (that.abschlussdatum) {
              that.abschlussdatum       = that.javaDateToMoment(that.abschlussdatum).format('YYYY-MM-DD') // that.javaDateToHtml5Date(that.abschlussdatum);
            }

            that.vorbildung             = daten.data.vorbildung;

            that.vorherigeUniversitaet  = daten.data.vorherigeUniversitaet;
            that.vorherigerBereich      = daten.data.vorherigerBereich;
            that.vorherigerAbschluss    = daten.data.vorherigerAbschluss ? that.javaDateToMoment(daten.data.vorherigerAbschluss).format('YYYY-MM-DD') : null;

          }

        }

      )

    },
    /** Masterreife im Nachhinein hinzufügen */
    saveMasterreife: function () {

        if (this.masterValid) {

            var params = {
                staatMasterreife:             this.staatMasterreife ? this.staatMasterreife.id : null,
                ortMasterreife:               this.ortMasterreife,
                ortobjMasterreife:            this.ortobjMasterreife ? this.ortobjMasterreife.id : null,
                masterreifeVorbildung:        this.masterreifeVorbildung ? this.masterreifeVorbildung.id : null,
                masterreifeDatum:             this.masterreifeDatum,
                bewerbung_id:                 this.getBewerbung()
            };

            var that = this;
            this.showSpinner();
            var url = process.env.VUE_APP_SERVER_URL + '/universitaetsreife/masterreife';
            axios({
                method: 'post',
                url: url,
                withCredentials: true,
                params: params
            }).then(function (daten) {

                  that.hideSpinner();

                  // Erfolgsmeldung ausgeben:
                  if (daten.data.redirectTo) {
                    that.isMasterreifeSaved = true;
                    bootbox.alert({
                      message: daten.data.message,
                      backdrop: true,
                      /** weiterleiten auf File Upload oder wenn Master Studium auf Daten ergänzen */
                      callback: function () {

                        that.$router.push(daten.data.redirectTo);

                      }
                    });
                  } else {
                    bootbox.alert({
                      message: that.messages['fh.reife.master.saved.error'],
                      backdrop: true
                    });
                  }

                }
            );

        }

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();
      if (this.formValid) {

        var incoming = null;

        if (this.bewerbergruppe === 'INCOMING' && this.isMasterstudium) {

          incoming = {
            vorherigeUniversitaet: this.vorherigeUniversitaet,
            vorherigerAbschluss: this.vorherigerAbschluss,
            vorherigerBereich: this.vorherigerBereich
          };

        }

        var params = {
          incoming:                     incoming ? JSON.stringify(incoming) : null,
          staatUniversitaetsreife:      this.staat ? this.staat.id : null,
          ortUniversitaetsreife:        this.ort,
          ortobjUniversitaetsreife:     this.ortobj ? this.ortobj.id : null,
          universitaetsreifeVorbildung: this.vorbildung ? this.vorbildung.id : null,
          universitaetsreifeDatum:      this.reifepruefungDatum,
          staatMasterreife:             this.staatMasterreife ? this.staatMasterreife.id : null,
          ortMasterreife:               this.ortMasterreife,
          ortobjMasterreife:            this.ortobjMasterreife ?  this.ortobjMasterreife.id : null,
          masterreifeVorbildung:        this.masterreifeVorbildung ? this.masterreifeVorbildung.id : null,
          masterreifeDatum:             this.masterreifeDatum,
          schulbildungen:               JSON.stringify(this.schulbildung),
          studienrichtung:              this.studienrichtung,
          instutition:                  this.instutition,
          abschlussdatum:               this.abschlussdatum,
          bewerbung_id:                 this.getBewerbung()
        };

        var that = this;
        this.showSpinner();
        var url = process.env.VUE_APP_SERVER_URL + '/universitaetsreife';
        axios({
          method: 'post',
          url: url,
          withCredentials: true,
          params: params
        }).then(function (daten) {
            that.hideSpinner();
            if (daten && daten.data && daten.data.id) {

              // weiter zum Dateiupload
              that.next();

            }

          }
        );

      }

    },
    next: function () {

      // weiter zur Umfrage
      this.$router.push('Upload');

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      this.sprache = language || localStorage.language;

      var messages = [
        'fh.nav.universitaetsreife',
        'default.button.forward.label',
        'fh.reife.staat',
        'fh.reife.staat.invalid',
        'fh.reife.vorbildung',
        'fh.reife.vorbildung.insert',
        'fh.reife.vorbildung.insert.master',
        'fh.reife.reifepruefung.datum',
        'fh.reife.reifepruefung.datum.insert',
        'fh.reife.schulbildung',
        'fh.reife.schulbildung.add',
        'fh.reife.schulbildung.remove',
        'fh.reife.staatMasterreife',
        'fh.reife.staatMasterreife.invalid',
        'fh.reife.masterreifeVorbildungen',
        'fh.reife.masterreifeDatum',
        'fh.reife.masterreifeDatum.insert',
        'fh.reife.masterreife',
        'fh.reife.hoechsteSchulbildung',
        'fh.reife',
        'fh.studiengang.incoming.vorhigesStudium',
        'fh.userData.incomings.vorherigeUniversitaet',
        'fh.userData.incomings.vorherigeUniversitaet.insert',
        'fh.userData.incomings.vorherigerBereich',
        'fh.userData.incomings.vorherigerBereich.insert',
        'fh.userData.incomings.vorherigerAbschluss',
        'fh.userData.incomings.vorherigerAbschluss.insert',
        'default.button.next.label',
        'fh.nav.universitaetsreife',
        'fh.nav.universitaetsreife.title',
        'fh.reife.master.save',
        'fh.reife.master.saved.error',
        'fh.reife.vorbildung.ort',
        'fh.reife.vorbildung.ort.insert',
        'fh.reife.vorbildung.ort.insert.master',
        'fh.studiengang.select'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.nav.universitaetsreife.title'];
      });

    }

  }

}
</script>
