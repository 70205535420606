<template>

  <form @submit="checkForm" name="userform" id="userform">

    <transition name="fade" mode="out-in">

      <div v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'UserData'" :student="student" v-if="bewerbergruppeAndCode" />

        <hr class="mt-5 mb-5">

        <!-- Auswahl der Bewerbergruppe -->
        <div class="form-group row">

          <div class="col-md-2">
            <label for="bewerbergruppe" class="col-form-label">
              <span v-html="messages['fh.studiengang.bewerbergruppe']" />
            </label>
          </div>
          <div class="col-md-10">
            <select-bewerbergruppe-list-group
               id="bewerbergruppe"
               v-on:set-bewerbergruppe="setBewerbergruppe"
               :readonly="abgeschlossen"
            />
          </div>

        </div>

        <!-- Code bei Bewerbergruppe INCOMING und AO Hörer -->
        <transition name="fade" mode="out-in">
          <div class="form-group row" v-if="(bewerbergruppe === 'INCOMING' || bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') && !abgeschlossen">

            <div class="col-md-2">
              <label for="code" class="col-form-label">
                <span v-html="messages['fh.studiengang.bewerbergruppe.code']"></span>
              </label>
            </div>
            <div class="col-md-10">
              <input type="text"
                     maxlength="200"
                     class="form-control"
                     v-bind:class="{ 'is-invalid': !bewerbergruppeAndCode }"
                     name="code"
                     id="code"
                     :placeholder="messages['fh.studiengang.bewerbergruppe.code']"
                     v-model.trim="code"
                     required
                     :readonly="abgeschlossen"
              />
              <div class="invalid-feedback">
                {{ messages['fh.studiengang.bewerbergruppe.code.insert'] }}
              </div>
            </div>

          </div>
        </transition>

        <!-- Studienrichtung bei Bewerbergruppe AO Hörer -->
        <transition name="fade" mode="out-in" v-if="bewerbergruppeAndCode && bewerbergruppe === 'AUSSERORDENTLICHER_HOERER'">
          <div class="form-group row">
            <div class="col-md-2">
              <label for="studienrichtungAngestrebt" class="col-form-label">{{ messages['fh.userData.incomings.studienrichtungAngestrebt'] }}*</label>
            </div>
            <div class="col-md-10">
              <select-studiengang :invalid-message="messages['fh.userData.incomings.studienrichtungAngestrebt.insert']"
                                  :studiengang="studienrichtungAngestrebt"
                                  id="studienrichtungAngestrebtAO"
                                  v-on:set-studiengang="setStudiengangAngestrebt"
                                  :readonly="abgeschlossen"
              />
              <div class="invalid-feedback">
                {{ messages['fh.userData.incomings.studienrichtungAngestrebt.insert'] }}
              </div>
            </div>
          </div>
        </transition>

        <hr class="mt-5 mb-5">

        <transition name="fade" mode="out-in">
          <div v-if="bewerbergruppeAndCode">

            <h2>{{ messages['fh.userData.persoenlicheDaten'] }}</h2>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="geschlecht" class="col-form-label">{{ messages['fh.userData.sex'] }}*</label>
              </div>
              <div class="col-md-10">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio"
                         id="geschlecht"
                         name="geschlecht"
                         class="custom-control-input"
                         v-bind:class="{ 'is-invalid': !isGeschlechtValid }"
                         required
                         v-model="geschlecht"
                         value="WEIBLICH"
                         :disabled="abgeschlossen">
                  <label class="custom-control-label" for="geschlecht">{{ messages['fh.userData.sex.f'] }}</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio"
                         id="geschlecht2"
                         name="geschlecht"
                         class="custom-control-input"
                         v-bind:class="{ 'is-invalid': !isGeschlechtValid }"
                         required
                         v-model="geschlecht"
                         value="MAENNLICH"
                         :disabled="abgeschlossen">
                  <label class="custom-control-label" for="geschlecht2">{{ messages['fh.userData.sex.m'] }}</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio"
                         id="geschlecht3"
                         name="geschlecht"
                         class="custom-control-input"
                         v-bind:class="{ 'is-invalid': !isGeschlechtValid }"
                         required
                         v-model="geschlecht"
                         value="DIVERSE"
                         :disabled="abgeschlossen">
                  <label class="custom-control-label" for="geschlecht3">{{ messages['fh.userData.sex.d'] }}</label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="vorname" class="col-form-label">{{ messages['fh.userData.vorname'] }}*</label>
              </div>
              <div class="col-md-10">
                <input type="text"
                       maxlength="40"
                       class="form-control"
                       v-bind:class="{ 'is-invalid': !isVornameValid }"
                       name="vorname"
                       id="vorname"
                       :placeholder="messages['fh.userData.vorname']"
                       v-model.trim="vorname"
                       required
                       :readonly="abgeschlossen">
                <div class="invalid-feedback">
                  {{ messages['fh.userData.vorname.insert'] }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="nachname" class="col-form-label">{{ messages['fh.userData.nachname'] }}*</label>
              </div>
              <div class="col-md-10">
                <input type="text"
                       maxlength="40"
                       class="form-control"
                       v-bind:class="{ 'is-invalid': !isNachnameValid }"
                       name="nachname"
                       id="nachname"
                       :placeholder="messages['fh.userData.nachname']"
                       v-model.trim="nachname"
                       required
                       :readonly="abgeschlossen">
                <div class="invalid-feedback">
                  {{ messages['fh.userData.nachname.insert'] }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="nationalitaet" class="col-form-label">{{ messages['fh.userData.nationalitaet'] }}*</label>
              </div>
              <div class="col-md-10">
                <select-land :invalid-message="messages['fh.userData.nationalitaet.insert']"
                             :land="nationalitaet"
                             id="nationalitaet"
                             v-on:set-land="setNationalitaet"
                             :readonly="abgeschlossen">
                </select-land>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="geburtsdatum" class="col-form-label">{{ messages['fh.userData.geburtsdatum'] }}*</label>
              </div>
              <div class="col-md-10">
                <utilo-date-picker
                  v-if="sprache && !abgeschlossen"
                  :datum="geburtsdatum"
                  id="geburtsdatum"
                  v-on:input="setGeburtsdatum"
                  class="form-control"
                  :startjahr="startjahr"
                  :language="sprache"
                  v-bind:class="{ 'is-invalid': !isGeburtsdatumValid }"
                  name="geburtsdatum">
                </utilo-date-picker>
                <readonly-date-input v-else :date="geburtsdatum"></readonly-date-input>
                <div class="invalid-feedback date-picker">
                  {{ messages['fh.userData.geburtsdatum.insert'] }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-2">
                <label for="telefon" class="col-form-label">{{ messages['fh.userData.telefon'] }}*</label>
              </div>
              <div class="col-md-10">
                <input type="text"
                       maxlength="30"
                       class="form-control"
                       v-bind:class="{ 'is-invalid': !isTelefonValid }"
                       name="telefon"
                       id="telefon"
                       :placeholder="messages['fh.userData.telefon']"
                       v-model.trim="telefon"
                       required
                       :readonly="abgeschlossen">
                <div class="invalid-feedback">
                  {{ messages['fh.userData.telefon.insert'] }}
                </div>
              </div>
            </div>

            <hr class="mt-5 mb-5">

            <h2>{{ messages['fh.userData.heimatadresse'] }}</h2>

            <select-land-and-ort :land="land"
                                 :land-error-message="messages['fh.userData.land.insert']"
                                 :land-label="messages['fh.userData.land']"
                                 :readonly="abgeschlossen"
                                 :ort="ort"
                                 :ort-label="messages['fh.userData.ort']"
                                 :ort-error-message="messages['fh.userData.ort.insert']"
                                 :ortobj="ortobj"
                                 v-on:set-land-and-ort="setLandAndOrt">
            </select-land-and-ort>

            <transition name="fade" mode="out-in">
              <div class="form-group row" v-show="land && land.iso3166 === 'A'">
                <div class="col-md-2">
                  <label for="bundesland" class="col-form-label">{{ messages['fh.userData.bundesland'] }}*</label>
                </div>
                <div class="col-md-10">
                  <select-bundesland
                       :invalid-message="messages['fh.userData.bundesland.insert']"
                       :bundesland="bundesland"
                       id="bundesland"
                       v-on:set-bundesland="setBundesland"
                       :readonly="abgeschlossen"
                  />
                </div>
              </div>
            </transition>

            <transition name="fade" mode="out-in">
              <div v-show="land && land.id">

                <div class="form-group row" v-show="land && land.iso3166 !== 'A'">
                  <div class="col-md-2">
                    <label for="plz" class="col-form-label">{{ messages['fh.userData.plz'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="15"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isPlzValid }"
                           name="plz"
                           id="plz"
                           :placeholder="messages['fh.userData.plz']"
                           v-model.trim="plz"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.plz.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="strasse" class="col-form-label">{{ messages['fh.userData.strasse'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="200"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isStrasseValid }"
                           name="strasse"
                           id="strasse"
                           :placeholder="messages['fh.userData.strasse']"
                           v-model.trim="strasse"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.strasse.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="hausnummer" class="col-form-label">{{ messages['fh.userData.hausnummer'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="10"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isHausnummerValid }"
                           name="hausnummer"
                           id="hausnummer"
                           :placeholder="messages['fh.userData.hausnummer']"
                           v-model.trim="hausnummer"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.hausnummer.insert'] }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="fade" mode="out-in">
              <div v-if="bewerbergruppe === 'INCOMING'">

                <hr class="mt-5 mb-5">

                <h2>{{ messages['fh.userData.incomings.studienjahrUndRichtung'] }}</h2>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="heimatuniversitaet" class="col-form-label">{{ messages['fh.userData.incomings.studienjahrSemester'] }}*</label>
                  </div>
                  <div class="col-md-10">

                    <select-studienjahr-semester v-on:set-studienjahr-semester="setStudienjahrSemester"
                                                 :studienjahr="studienjahr"
                                                 :semester="studienjahrSemester"
                                                 :studienjahr2="studienjahr2"
                                                 :semester2="studienjahrSemester2"
                                                 :readonly="abgeschlossen"
                    />
                    <div class="invalid-feedback" :style="!isStudienjahrSemesterValid ? 'display:block' : ''">
                      {{ messages['fh.userData.incomings.studienjahrSemester.insert'] }}
                    </div>

                  </div>
                </div>

                <span v-if="studienjahr || studienjahr2">

                  <div class="form-group row">
                    <div class="col-md-2">
                      <label for="studienrichtung" class="col-form-label">{{ messages['fh.userData.incomings.studienrichtung'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text"
                             maxlength="100"
                             class="form-control"
                             v-bind:class="{ 'is-invalid': !isStudienrichtungValid }"
                             name="studienrichtung"
                             id="studienrichtung"
                             :placeholder="messages['fh.userData.incomings.studienrichtung']"
                             v-model.trim="studienrichtung"
                             required
                             :readonly="abgeschlossen">
                      <div class="invalid-feedback">
                        {{ messages['fh.userData.incomings.studienrichtung.insert'] }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-2">
                      <label for="studienrichtungAngestrebt" class="col-form-label">{{ messages['fh.userData.incomings.studienrichtungAngestrebt'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <select-studiengang :invalid-message="messages['fh.userData.incomings.studienrichtungAngestrebt.insert']"
                                         :studiengang="studienrichtungAngestrebt"
                                         id="studienrichtungAngestrebt"
                                         v-on:set-studiengang="setStudiengangAngestrebt"
                                         :readonly="abgeschlossen"
                      />
                      <div class="invalid-feedback">
                        {{ messages['fh.userData.incomings.studienrichtungAngestrebt.insert'] }}
                      </div>
                    </div>
                  </div>

                </span>

                <hr class="mt-5 mb-5">

                <h2>{{ messages['fh.userData.incomings.heimatuniversitaet.h1'] }}</h2>
                <h3>{{ messages['fh.userData.incomings.heimatuniversitaet.adresse'] }}</h3>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="heimatuniversitaet" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <select-partner-universitaet :partner-universitaet="heimatuniversitaet"
                                                 :invalid-message="messages['fh.userData.incomings.heimatuniversitaet.insert']"
                                                 id="heimatuniversitaet"
                                                 v-on:set-partner-universitaet="setHeimatuniversitaet"
                                                 :readonly="abgeschlossen"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="betreuer" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.adresse.betreuer'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="128"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isBetreuerValid }"
                           name="betreuer"
                           id="betreuer"
                           :placeholder="messages['fh.userData.incomings.heimatuniversitaet.adresse.betreuer']"
                           v-model.trim="betreuer"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.adresse.betreuer.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="taetigkeitsbezeichnung" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.adresse.taetigkeitsbezeichnung'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="200"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isTaetigkeitsbezeichnungValid }"
                           name="taetigkeitsbezeichnung"
                           id="taetigkeitsbezeichnung"
                           :placeholder="messages['fh.userData.incomings.heimatuniversitaet.adresse.taetigkeitsbezeichnung']"
                           v-model.trim="taetigkeitsbezeichnung"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.adresse.taetigkeitsbezeichnung.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="email" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.adresse.email'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="email"
                           maxlength="100"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isEmailValid }"
                           name="email"
                           id="email"
                           :placeholder="messages['fh.userData.incomings.heimatuniversitaet.adresse.email']"
                           v-model="email"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.adresse.email.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="uniTelefon" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.adresse.telefon'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="30"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isUniTelefonValid }"
                           name="uniTelefon"
                           id="uniTelefon"
                           :placeholder="messages['fh.userData.incomings.heimatuniversitaet.adresse.telefon']"
                           v-model.trim="uniTelefon"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.adresse.telefon.insert'] }}
                    </div>
                  </div>
                </div>

                <h3>{{ messages['fh.userData.incomings.heimatuniversitaet.studium'] }}</h3>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="inskriptionsdatum" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.studium.inskriptionsdatum'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <utilo-date-picker
                      :datum="inskriptionsdatum"
                      id="inskriptionsdatum"
                      class="form-control"
                      v-bind:class="{ 'is-invalid': !isInskriptionsdatumValid }"
                      :language="sprache"
                      name="inskriptionsdatum"
                      v-on:input="setInskriptionsdatum"
                      v-if="sprache && !abgeschlossen">
                    </utilo-date-picker>
                    <readonly-date-input :date="inskriptionsdatum" v-else></readonly-date-input>
                    <div class="invalid-feedback date-picker">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.studium.inskriptionsdatum.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">

                  <div class="col-md-2">
                    <label for="semester" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.studium.semester'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="number"
                           min="0"
                           step="1"
                           max="30"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isSemesterValid }"
                           name="semester"
                           id="semester"
                           :placeholder="messages['fh.userData.incomings.heimatuniversitaet.studium.semester']"
                           v-model="semester"
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.heimatuniversitaet.studium.semester.insert'] }}
                    </div>
                  </div>

                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="abschluss" class="col-form-label">{{ messages['fh.userData.incomings.heimatuniversitaet.studium.abschluss'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <select-abschluss
                      :abschluss="abschluss"
                      name="abschluss"
                      v-on:set-abschluss="setAbschluss"
                      id="abschluss"
                      :invalid-message="messages['fh.userData.incomings.heimatuniversitaet.studium.abschluss.insert']"
                      :readonly="abgeschlossen">
                    </select-abschluss>

                  </div>
                </div>

                <hr class="mt-5 mb-5">

                <h2>{{messages['fh.userData.incomings.sprachkenntnisse']}}</h2>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="muttersprache" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.muttersprache'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="40"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isMutterspracheValid }"
                           name="muttersprache"
                           id="muttersprache"
                           :placeholder="messages['fh.userData.incomings.sprachkenntnisse.muttersprache']"
                           v-model.trim="muttersprache"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.sprachkenntnisse.muttersprache.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="unterrichtssprache" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.unterrichtssprache'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"
                           maxlength="40"
                           class="form-control"
                           v-bind:class="{ 'is-invalid': !isUnterrichtsspracheValid }"
                           name="unterrichtssprache"
                           id="unterrichtssprache"
                           :placeholder="messages['fh.userData.incomings.sprachkenntnisse.unterrichtssprache']"
                           v-model.trim="unterrichtssprache"
                           required
                           :readonly="abgeschlossen">
                    <div class="invalid-feedback">
                      {{ messages['fh.userData.incomings.sprachkenntnisse.unterrichtssprache.insert'] }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="toefl" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="toefl"
                             name="toefl"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isToeflValid }"
                             required
                             v-model="toefl"
                             value="YES"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="toefl">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.yes'] }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="toefl2"
                             name="toefl"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isToeflValid }"
                             required
                             v-model="toefl"
                             value="NO"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="toefl2">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.no'] }}</label>
                    </div>
                  </div>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="form-group row" v-if="toefl && toefl === 'YES'">
                    <div class="col-md-2">
                      <label for="toeflPunkte" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.toeflPunkte'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <input type="number"
                             min="0"
                             step="1"
                             max="120"
                             class="form-control"
                             v-bind:class="{ 'is-invalid': !isToeflPunkteValid }"
                             name="toeflPunkte"
                             id="toeflPunkte"
                             :placeholder="messages['fh.userData.incomings.sprachkenntnisse.toeflPunkte']"
                             v-model="toeflPunkte"
                             :readonly="abgeschlossen">
                      <div class="invalid-feedback">
                        {{ messages['fh.userData.incomings.sprachkenntnisse.toeflPunkte.insert'] }}
                      </div>
                    </div>
                  </div>
                </transition>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="andererTest" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.andererTest'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="andererTest"
                             name="andererTest"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isAndererTestValid }"
                             required
                             v-model="andererTest"
                             value="YES"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="andererTest">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.yes'] }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="andererTest2"
                             name="andererTest"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isAndererTestValid }"
                             required
                             v-model="andererTest"
                             value="NO"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="andererTest2">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.no'] }}</label>
                    </div>
                  </div>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="form-group row" v-if="andererTest && andererTest === 'YES'">
                    <div class="col-md-2">
                      <label for="englischTest" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.englischTest'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text"
                             maxlength="120"
                             class="form-control"
                             v-bind:class="{ 'is-invalid': !isEnglischTestValid }"
                             name="englischTest"
                             id="englischTest"
                             :placeholder="messages['fh.userData.incomings.sprachkenntnisse.englischTest']"
                             v-model.trim="englischTest"
                             required
                             :readonly="abgeschlossen">
                      <div class="invalid-feedback">
                        {{ messages['fh.userData.incomings.sprachkenntnisse.englischTest.insert'] }}
                      </div>
                    </div>
                  </div>
                </transition>

                <transition name="fade" mode="out-in">
                  <div class="form-group row" v-if="andererTest && andererTest === 'YES'">
                    <div class="col-md-2">
                      <label for="englischTestPunkte" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.englischTestPunkte'] }}*</label>
                    </div>
                    <div class="col-md-10">
                      <input type="number"
                             min="0"
                             step="1"
                             class="form-control"
                             v-bind:class="{ 'is-invalid': !isEnglischTestPunkteValid }"
                             name="englischTestPunkte"
                             id="englischTestPunkte"
                             :placeholder="messages['fh.userData.incomings.sprachkenntnisse.englischTestPunkte']"
                             v-model="englischTestPunkte"
                             :readonly="abgeschlossen">
                      <div class="invalid-feedback">
                        {{ messages['fh.userData.incomings.sprachkenntnisse.englischTestPunkte.insert'] }}
                      </div>
                    </div>
                  </div>
                </transition>

                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="deutschKenntnisse" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.deutschKenntnisse'] }}*</label>
                  </div>
                  <div class="col-md-10">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="deutschKenntnisse"
                             name="deutschKenntnisse"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isDeutschKenntnisseValid }"
                             required
                             v-model="deutschKenntnisse"
                             value="YES"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="deutschKenntnisse">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.yes'] }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio"
                             id="deutschKenntnisse2"
                             name="deutschKenntnisse"
                             class="custom-control-input"
                             v-bind:class="{ 'is-invalid': !isDeutschKenntnisseValid }"
                             required
                             v-model="deutschKenntnisse"
                             value="NO"
                             :disabled="abgeschlossen">
                      <label class="custom-control-label" for="deutschKenntnisse2">{{ messages['fh.userData.incomings.sprachkenntnisse.toefl.no'] }}</label>
                    </div>
                  </div>
                </div>

                <h3>{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau'] }}</h3>

                <!-- Sprachkenntnisse nach Common European Framework of Reference for Languages -->
                <select-sprachkenntnisse
                  :readonly="abgeschlossen ? true : false"
                  :deutsch-score="deutschScore"
                  :englisch-score="englischScore"
                  v-on:set-score="setScore"
                />

              </div><!-- End Incomings -->
            </transition>

            <hr class="mt-5">

            <div class="form-group row">
              <div class="col-md-10 offset-col-md-2">
                <button type="submit"
                        v-if="!abgeschlossen"
                        class="button primary"
                        :disabled="!formValid">{{ messages['default.button.forward.label'] }}</button>
                <button type="button"
                        v-on:click="next()"
                        v-else
                        class="button primary">{{ messages['default.button.next.label'] }}</button>
              </div>
            </div>

          </div>

        </transition>

      </div>

    </transition>

  </form>

</template>
<style scoped>
    h3, .h3 {
        padding-bottom: 1em;
        padding-top: 0.5em;
    }
</style>
<script>

import axios                          from 'axios';
import UtiloDatePicker                from './layout/UtiloDatePicker';
import moment                         from 'moment';
import SelectLand                     from './user/SelectLand.vue';
import SelectLandAndOrt               from './user/SelectLandAndOrt.vue';
import SelectBundesland               from './user/SelectBundesland.vue';
import SelectStudiengang              from './user/SelectStudiengang.vue';
import NavTabs                          from './layout/NavTabs.vue';
import CheckLogin                     from './login/CheckLogin.vue';
import SelectBewerbergruppeListGroup  from './studiengang/SelectBewerbergruppeListGroup.vue';
import SelectStudienjahrSemester      from './user/SelectStudienjahrSemester.vue';
import SelectPartnerUniversitaet      from './user/SelectPartnerUniversitaet.vue';
import ReadonlyDateInput              from './layout/ReadonlyDateInput.vue';
import SelectAbschluss                from './user/SelectAbschluss.vue';
import SelectSprachkenntnisse         from './user/SelectSprachkenntnisse';
// Import the EventBus.
import { EventBus } from '../event-bus.js';
import bootbox from 'bootbox'

export default {

  name: 'UserData',
  components: {

      SelectSprachkenntnisse,
      SelectLand,
      SelectLandAndOrt,
      UtiloDatePicker,
      CheckLogin,
      NavTabs,
      SelectBewerbergruppeListGroup,
      SelectStudiengang,
      SelectBundesland,
      SelectStudienjahrSemester,
      SelectPartnerUniversitaet,
      ReadonlyDateInput,
      SelectAbschluss,
  },
  data () {
    return {
      messages: null,
      geschlecht: null,
      vorname: null,
      nachname: null,
      geburtsdatum: null,
      nationalitaet: { id: null, name: '-- bitte wählen --' },
      telefon: null,
      /** Heimatadresse Land */
      land: { id: null, name: '-- bitte wählen --' },
      bundesland: null,
      strasse: null,
      hausnummer: null,
      plz: null,
      /** eingegebener Ortsname */
      ort: null,
      /** Orts Object für Österreich */
      ortobj: null,
      student: null,
      bewerbergruppe: null,
      // Incomings:
      heimatuniversitaet: null,
      betreuer: null,
      taetigkeitsbezeichnung: null,
      email: null,
      uniTelefon: null,
      inskriptionsdatum: null,
      semester: null,
      abschluss: null,
      muttersprache: null,
      unterrichtssprache: null,
      toefl: null,
      toeflPunkte: null,
      andererTest: null,
      englischTest: null,
      englischTestPunkte: null,
      deutschKenntnisse: null,
      /** A1, A2, ... C2 */
      englischScore: null,
      // englischScoreName: null,
      /** A1, A2, ... C2 */
      deutschScore: null,
      // deutschScoreName: null,
      scoreData: null,
      /** INCOMING oder AHörer Code */
      code: null,
      /** true wenn ein korrekter Code eingegeben wurde */
      isCodeCorrect: false,
      studienrichtung: null,
      studienrichtungAngestrebt: null,
      studienjahr: null,
      studienjahrSemester: null,
      studienjahr2: null,
      studienjahrSemester2: null,
      abgeschlossen: null,
      /** Sprache für den Datepicker */
      sprache: null,
      /** Geburtsdatum Datepicker starten now - 18 Jahre */
      startjahr: (new Date()).getUTCFullYear() - 18,
    }
  },
  mounted: function () {

    this.init();

  },
  computed: {

    /** prüft ob die Bewerbergruppe ausgewählt wurde und wenn INCOMING ob der Code korrekt ist */
    bewerbergruppeAndCode: function() {

      if (!this.bewerbergruppe) return false;

      // wenn die Bewerbung schon abgeschlossen ist, dann wurde auch ein korrekter Code eingegeben:
      if (this.abgeschlossen) return true;

      if ((this.bewerbergruppe === 'INCOMING' || this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') && !this.isCodeCorrect) return false;

      return true

    },
    isStudienjahrSemesterValid: function () {

      if (!this.studienjahrSemester && !this.studienjahrSemester2) return false;
      return true;

    },
    isStudienrichtungValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (this.studienrichtung === null) return false;
      return true;

    },
    isStudienrichtungAngestrebtValid: function () {

      if (this.bewerbergruppe === 'STUDENT') return true;

      if (this.studienrichtungAngestrebt === null) return false;
      return true;

    },
    isDeutschKenntnisseValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (this.deutschKenntnisse === null) return false;
      return true;

    },
    isEnglischTestPunkteValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.isInt(this.englischTestPunkte)) {
        console.log('englischTestPunkte ist kein Integer ' + this.englischTestPunkte);
        return false;
      }

      if (!this.englischTestPunkte || this.englischTestPunkte < 0 || this.englischTestPunkte > 99999) return false;
      return true;

    },
    isEnglischTestValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.englischTest || this.englischTest === '' || this.englischTest.length < 2) return false;
      return true;

    },
    isAndererTestValid: function() {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (this.andererTest === null) return false;
      return true;

    },
    isToeflPunkteValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.isInt(this.toeflPunkte)) {
        console.log('Toefl ist kein Integer ' + this.toeflPunkte, 10);
        return false;
      }

      if (this.toeflPunkte === null || this.toeflPunkte < 0 || this.toeflPunkte > 120) return false;
      return true;

    },
    isToeflValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (this.toefl === null) return false;
      return true;

    },
    isUnterrichtsspracheValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.unterrichtssprache || this.unterrichtssprache === '' || this.unterrichtssprache.length < 2) return false;
      return true;

    },
    isMutterspracheValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.muttersprache || this.muttersprache === '' || this.muttersprache.length < 2) return false;
      return true;

    },
    isAbschlussValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.abschluss || !this.abschluss.id) return false;
      return true;

    },
    isSemesterValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.semester || this.semester < 0 || this.semester > 30) return false;
      return true;

    },
    isInskriptionsdatumValid: function() {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.inskriptionsdatum) return false;

      // stimmt das Datumsformat?
      var DATE_FORMAT = 'YYYY-MM-DD';
      if (moment(this.inskriptionsdatum, DATE_FORMAT).format(DATE_FORMAT) !== this.inskriptionsdatum) {
        return false;
      }

      return true;

    },
    isUniTelefonValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      var str = '' + this.uniTelefon;
      if (!str || str === '' || str.length < 2 || str.length > 30) return false;
      return this.validPhone(str);

    },
    isEmailValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.email) return false;
      return this.validEmail(this.email);

    },
    isTaetigkeitsbezeichnungValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.taetigkeitsbezeichnung || this.taetigkeitsbezeichnung === '' || this.taetigkeitsbezeichnung.length < 2) return false;
      return true;

    },
    isBetreuerValid: function () {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.betreuer || this.betreuer === '' || this.betreuer.length < 2) return false;
      return true;

    },
    isHeimatuniversitaetValid: function() {

      if (this.bewerbergruppe !== 'INCOMING') return true;

      if (!this.heimatuniversitaet || !this.heimatuniversitaet.id) return false;
      return true;

    },
    isGeschlechtValid: function() {
      if (!this.geschlecht) return false;
      return true;
    },
    isVornameValid: function() {
      if (!this.vorname || this.vorname === '' || this.vorname.length < 2) return false;
      return true;
    },
    isNachnameValid: function() {
      if (!this.nachname || this.nachname === '' || this.nachname.length < 2) return false;
      return true;
    },
    isNationalitaetValid: function() {
      if (!this.nationalitaet || this.nationalitaet.id == null) {
        return false;
      }
      return true;
    },
    isGeburtsdatumValid: function() {

      if (!this.geburtsdatum) return false;

      // stimmt das Datumsformat?
      var DATE_FORMAT = 'YYYY-MM-DD';
      if (moment(this.geburtsdatum, DATE_FORMAT).format(DATE_FORMAT) !== this.geburtsdatum) {
        return false;
      }

      // prüfe ob Geburtsdatum möglich sein kann:
      /* The difference, in years, between NOW and this.geburtsdatum */
      var alter = moment().diff(moment(this.geburtsdatum, 'YYYY-MM-DD'), 'years');
      if (alter < 14 || alter > 99) {
         return false;
      }
      return true;

    },
    isTelefonValid: function() {

      var str = '' + this.telefon;
      if (!str || str === '' || str.length < 5 || str.length > 30) return false;
      return this.validPhone(str);

    },
    isLandValid: function() {
      if (!this.land || this.land.id == null) return false;
      return true;
    },
    isBundeslandValid: function() {

      if (this.land && this.land.iso3166 === 'A') {
        if (!this.bundesland) return false;
      }
      return true;

    },
    isStrasseValid: function() {
      if (!this.strasse || this.strasse === '' || this.strasse.length < 2) return false;
      return true;
    },
    isHausnummerValid: function() {
      if (!this.hausnummer || this.hausnummer === '' || this.hausnummer.length < 1) return false;
      return true;
    },
    isPlzValid: function() {
      if (!this.plz || this.plz === '' || this.plz.length < 2) return false;
      return true;
    },
    isOrtValid: function() {
      if (!this.ort || this.ort === '' || this.ort.length < 2) return false;
      return true;
    },
    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      // Incoming prüfen
      if (this.bewerbergruppe === 'INCOMING') {

        if (!this.isDeutschKenntnisseValid ||
            !this.isToeflValid ||
            !this.isUnterrichtsspracheValid ||
            !this.isMutterspracheValid ||
            !this.isSemesterValid ||
            !this.isAbschlussValid ||
            !this.isInskriptionsdatumValid ||
            !this.isUniTelefonValid ||
            !this.isEmailValid ||
            !this.isTaetigkeitsbezeichnungValid ||
            !this.isBetreuerValid ||
            !this.isHeimatuniversitaetValid ||
            !this.isStudienrichtungValid ||
            !this.isStudienrichtungAngestrebtValid ||
            !this.isStudienjahrSemesterValid) {
          return false;
        }

        if (this.toefl === 'YES' && !this.isToeflPunkteValid) return false;

        if (this.andererTest === 'YES' && (!this.isEnglischTestPunkteValid || !this.isEnglischTestValid)) return false;

      }

      // bei AO Hörer muss die Studienrichtung ausgewählt worden sein
      if (this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {

        if (!this.isStudienrichtungAngestrebtValid) return false;

      }

      if (this.isGeschlechtValid &&
          this.isVornameValid &&
          this.isNachnameValid &&
          this.isNationalitaetValid &&
          this.isGeburtsdatumValid &&
          this.isTelefonValid &&
          this.isLandValid &&
          this.isBundeslandValid &&
          this.isStrasseValid &&
          this.isPlzValid &&
          this.isOrtValid &&
          this.isHausnummerValid) {
        return true;
      }

      return false;

    }

  },
  watch: {

    /** prüft ob der INCOMING Code korrekt ist */
    code: function (val) {

      console.log('Prüfe Incoming Code ' + val);
      this.isCodeCorrect = false;
      var that = this;
      var url  = process.env.VUE_APP_SERVER_URL + '/student/checkIncomingCode';
      var params = {
        code:         this.code,
        bewerbung_id: this.getBewerbung()
      };
      axios.get(url, {
        withCredentials: true,
        params: params
      })
      .then(function (response) {
        if (response.data.success) {
          that.isCodeCorrect = true;
        }
      });

    },
    /** prüft ob der Student die Studienrichtung nicht in einer anderen Bewerbung ausgewählt hat */
    studienrichtungAngestrebt: function (val) {

      if (val && val.oracle_id) {

        this.checkDublikat();

      }

    },
    /** prüft ob der Student die Studienrichtung nicht in einer anderen Bewerbung ausgewählt hat, wenn studienjahr geändert wird */
    studienjahr: function () {

      if (this.studienrichtungAngestrebt) {

        this.checkDublikat();

      }

    },
    /** prüft ob der Student die Studienrichtung nicht in einer anderen Bewerbung ausgewählt hat, wenn studienjahr geändert wird */
    studienjahr2: function () {

      if (this.studienrichtungAngestrebt) {

        this.checkDublikat();

      }

    }

  },
  methods: {

    /** prüft auf doppelte Bewerbung */
    checkDublikat: function () {

      var params = {
        bewerbung_id:           this.getBewerbung(),
        studiengang_oracle_id:  this.studienrichtungAngestrebt.oracle_id,
        studienjahr:            this.studienjahr,
        semester:               this.studienjahrSemester,
        studienjahr2:           this.studienjahr2,
        semester2:              this.studienjahrSemester2
      }
      var url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl/checkDuplicate';
      var that   = this;
      axios({
        method: 'get',
        url: url,
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (response.data.hasDuplicate) {

            var message = that.messages['fh.studiengang.bereitsbeworben'];
            message     = message.replace('{0}', that.studienrichtungAngestrebt.name);
            message     = message.replace('{1}', that.javaDateToHtml5Date(response.data.bewerbung.dateCreated));
            bootbox.alert ({
              message:  message,
              backdrop: true,
              callback: function () {
                // deselektieren
                that.studienrichtungAngestrebt = null;
              }
            });

          }

        });

    },
    /** deutsch und/oder englisch score wurde gewählt */
    setScore: function (obj) {

      this.englischScore = obj.englischScore;
      this.deutschScore  = obj.deutschScore;

    },
    setLandAndOrt: function (obj) {

      this.land               = obj.land;
      this.ort                = obj.ort;
      this.ortobj             = obj.ortobj;
      if (this.ortobj) {
        this.plz = obj.ortobj.plz;
      }

    },
    /** Telefonnummer mit Pattern prüfen
     * (123) 456-7890
     * +(123) 456-7890
     * +(123)-456-7890
     * +(123) - 456-7890
     * +(123) - 456-78-90
     * 123-456-7890
     * 123.456.7890
     * 1234567890
     * +31636363634
     * 075-63546725
     */
    validPhone: function (phone) {

      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phone);

    },
    setGeburtsdatum: function (val) {
      this.geburtsdatum = val;
    },
    setInskriptionsdatum: function (val) {
      this.inskriptionsdatum = val;
    },
    setAbschluss: function (obj) {
      this.abschluss = obj;
    },
    setHeimatuniversitaet: function (val) {
      this.heimatuniversitaet = val;
    },
    setStudienjahrSemester: function (val) {

      /*
      obj = {
        semester1:    semester1,
        studienjahr1: studienjahr1,
        semester2:    semester2,
        studienjahr2: studienjahr2
      };
      */
      this.studienjahr           = val ? val.studienjahr1  : null;
      this.studienjahrSemester   = val ? val.semester1     : null;
      this.studienjahr2          = val ? val.studienjahr2 : null;
      this.studienjahrSemester2  = val ? val.semester2    : null;

    },
    setStudiengangAngestrebt: function (val) {
      this.studienrichtungAngestrebt = val;
    },
    setBewerbergruppe: function (val) {

      if (this.bewerbergruppe !== val) {
        // Code löschen:
        this.code           = null;
        this.isCodeCorrect  = false;
        this.bewerbergruppe = val;
        this.getStudentData();
      }

    },
    init: function () {

      console.log('init UserData ...');
      var that = this;

      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(function(res) {
        that.abgeschlossen = res;
      });

      // hole Benutzerdaten falls bereits welche vorhanden sind
      this.getStudentData();

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    /** holt Daten eines Studenten falls bereits vorhanden */
    getStudentData: function () {

      var that   = this;
      var url    = process.env.VUE_APP_SERVER_URL + '/student';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        withCredentials: true,
        params: params
      })
        .then(function (response) {

          console.log('Studentendaten setzen', response.data);
          if (response.data.student) {
            that.geschlecht       = response.data.geschlecht;
            that.vorname          = response.data.student.vorname;
            that.nachname         = response.data.student.nachname;
            var gebdat            = response.data.geburtsdatum ? '' + that.javaDateToMoment(response.data.geburtsdatum).format('YYYY-MM-DD') : null;
            console.log('gebdat in html5', gebdat)
            that.geburtsdatum     = gebdat;
            that.nationalitaet    = response.data.nationalitaet;
            that.telefon          = response.data.student.telefon;
            that.student          = response.data.student;
            that.aufenthaltstitel = response.data.aufenthaltstitel;
          }
          if (response.data.adresse) {
            that.land       = response.data.land;
            that.bundesland = response.data.bundesland;
            that.strasse    = response.data.adresse.strasse;
            that.plz        = response.data.adresse.plz;
            that.ort        = response.data.adresse.ort;
            that.ortobj     = response.data.ort;
            that.hausnummer = response.data.adresse.hausnummer;
          }
          if (response.data.bewerbung && response.data.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {
            console.log('Bewerbergruppe ' + response.data.bewerbergruppe + ' Studienrichtung: ' + response.data.studienrichtungAngestrebt);
            that.studienrichtungAngestrebt  = response.data.studienrichtungAngestrebt;
            that.code                       = response.data.code;
          }
          if (response.data.bewerbung && response.data.bewerbergruppe === 'INCOMING' && response.data.incoming) {

            that.code                       = response.data.code;
            that.heimatuniversitaet         = response.data.heimatUniversitaet;
            that.betreuer                   = response.data.incoming.betreuer;
            that.taetigkeitsbezeichnung     = response.data.incoming.taetigkeitsbezeichnung;
            that.email                      = response.data.incoming.email;
            that.uniTelefon                 = response.data.incoming.telefon;
            that.inskriptionsdatum          = response.data.incoming.inskriptionsdatum ? that.javaDateToMoment(response.data.incoming.inskriptionsdatum).format('YYYY-MM-DD') : null;
            that.semester                   = response.data.incoming.semester;
            that.abschluss                  = response.data.abschluss;
            that.muttersprache              = response.data.incoming.muttersprache;
            that.unterrichtssprache         = response.data.incoming.unterrichtssprache;
            that.toeflPunkte                = response.data.incoming.toeflPunkte;
            if (that.toeflPunkte) {
              that.toefl                    = 'YES';
            } else {
              that.toefl                    = 'NO';
            }
            that.englischTest               = response.data.incoming.englischTest;
            if (that.englischTest) {
              that.andererTest              = 'YES';
              that.englischTestPunkte       = response.data.incoming.englischTestPunkte;
            } else {
              that.andererTest              = 'NO';
            }
            that.deutschKenntnisse          = response.data.incoming.deutschKenntnisse === true ? 'YES' : 'NO';
            that.englischScore              = response.data.incoming.englischDaad;
            that.deutschScore               = response.data.incoming.deutschDaad;
            that.studienrichtung            = response.data.incoming.studienrichtung;
            that.studienrichtungAngestrebt  = response.data.studienrichtungAngestrebt;
            that.studienjahr                = response.data.incoming.studienjahr;
            that.studienjahrSemester        = response.data.incoming.studienjahrSemester;
            that.studienjahr2               = response.data.incoming.studienjahr2;
            that.studienjahrSemester2       = response.data.incoming.studienjahrSemester2;

          }

        })

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();

      if (this.formValid) {

        this.showSpinner();

        var url    = process.env.VUE_APP_SERVER_URL + '/student';
        var obj = {
          student: {
            geschlecht:       this.geschlecht,
            vorname:          this.vorname,
            nachname:         this.nachname,
            geburtsdatum:     this.geburtsdatum,
            nationalitaet:    this.nationalitaet.id,
            telefon:          this.telefon,
          },
          adresse: {
            land:         this.land.id,
            bundesland:   this.bundesland,
            strasse:      this.strasse,
            plz:          this.plz,
            ort:          this.ort,
            hausnummer:   this.hausnummer
          },
        }

        if (this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER' || this.bewerbergruppe === 'INCOMING') {
          obj.angestrebterStudiengang = this.studienrichtungAngestrebt; // komplettes Objekt übergeben!
          obj.code                    = this.code;
        }

        if (this.bewerbergruppe === 'INCOMING') {

          // sicherstellen dass bei Sprachpunkten nur Int übergeben wird:
          if (this.englischTestPunkte) this.englischTestPunkte = Math.trunc(this.englischTestPunkte);
          if (this.toeflPunkte) this.toeflPunkte = Math.trunc(this.toeflPunkte);

          var incoming = {
              heimatuniversitaet:       this.heimatuniversitaet ? this.heimatuniversitaet.id : null,
              betreuer:                 this.betreuer,
              taetigkeitsbezeichnung:   this.taetigkeitsbezeichnung,
              email:                    this.email,
              telefon:                  this.uniTelefon,
              inskriptionsdatum:        this.inskriptionsdatum,
              semester:                 this.semester,
              abschluss:                this.abschluss,
              muttersprache:            this.muttersprache,
              unterrichtssprache:       this.unterrichtssprache,
              toeflPunkte:              this.toefl === 'YES' ? this.toeflPunkte : null,
              englischTest:             this.andererTest === 'YES' ? this.englischTest : null,
              englischTestPunkte:       this.andererTest === 'YES' ? this.englischTestPunkte : null,
              deutschKenntnisse:        this.deutschKenntnisse,
              englischScore:            this.englischScore,
              deutschScore:             this.deutschScore,
              studienrichtung:          this.studienrichtung,
              studienjahr:              this.studienjahr,
              studienjahrSemester:      this.studienjahrSemester,
              studienjahr2:             this.studienjahr2,
              studienjahrSemester2:     this.studienjahrSemester2
          };

          obj.incoming = incoming

        }

        var params = {
          data: JSON.stringify(obj),
          bewerbung_id: this.getBewerbung()
        };
        var that = this;
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        }).then(function (response) {

          if (response) {
            that.hideSpinner();
            that.next();
          }

        });

      }

    },
    next: function () {

      if (this.bewerbergruppe && this.bewerbergruppe === 'STUDENT') {
        // weiter zur Studienwahl
        this.$router.push('Studiengang');
      } else {
        // OA Hörer und Incoming haben keine Studiengangswahl
        this.$router.push('Reife');
      }

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      this.sprache = language || localStorage.language;

      var messages = [
        'fh.userData.sex',
        'fh.userData.sex.m',
        'fh.userData.sex.f',
        'fh.userData.sex.d',
        'fh.userData.title',
        'fh.userData.vorname',
        'fh.userData.vorname.insert',
        'fh.userData.nachname',
        'fh.userData.nachname.insert',
        'fh.userData.nationalitaet',
        'fh.userData.nationalitaet.insert',
        'fh.userData.geburtsdatum',
        'fh.userData.geburtsdatum.insert',
        'fh.userData.telefon',
        'fh.userData.telefon.insert',
        'fh.userData.heimatadresse',
        'fh.userData.persoenlicheDaten',
        'fh.userData.land',
        'fh.userData.land.insert',
        'fh.userData.bundesland',
        'fh.userData.bundesland.insert',
        'fh.userData.strasse',
        'fh.userData.strasse.insert',
        'fh.userData.hausnummer',
        'fh.userData.hausnummer.insert',
        'fh.userData.plz',
        'fh.userData.plz.insert',
        'fh.userData.ort',
        'fh.userData.ort.insert',
        'default.button.forward.label',
        'fh.userData.geschlecht.insert',
        'fh.studiengang.bewerbergruppe',
        'fh.userData.incomings.heimatuniversitaet',
        'fh.userData.incomings.heimatuniversitaet.adresse',
        'fh.userData.incomings.heimatuniversitaet.studium',
        'fh.userData.incomings.heimatuniversitaet.studiumVorher',
        'fh.userData.incomings.sprachkenntnisse',
        'fh.userData.incomings.sprachkenntnisse.sprachniveau',
        'fh.userData.incomings.heimatuniversitaet.h1',
        'fh.userData.incomings.heimatuniversitaet.adresse.betreuer',
        'fh.userData.incomings.heimatuniversitaet.adresse.betreuer.insert',
        'fh.userData.incomings.heimatuniversitaet.adresse.taetigkeitsbezeichnung',
        'fh.userData.incomings.heimatuniversitaet.adresse.taetigkeitsbezeichnung.insert',
        'fh.userData.incomings.heimatuniversitaet.adresse.email',
        'fh.userData.incomings.heimatuniversitaet.adresse.email.insert',
        'fh.userData.incomings.heimatuniversitaet.adresse.telefon',
        'fh.userData.incomings.heimatuniversitaet.adresse.telefon.insert',
        'fh.userData.incomings.heimatuniversitaet.studium.inskriptionsdatum',
        'fh.userData.incomings.heimatuniversitaet.studium.inskriptionsdatum.insert',
        'fh.userData.incomings.heimatuniversitaet.studium.semester',
        'fh.userData.incomings.heimatuniversitaet.studium.semester.insert',
        'fh.userData.incomings.heimatuniversitaet.studium.abschluss',
        'fh.userData.incomings.heimatuniversitaet.studium.abschluss.insert',
        'fh.userData.incomings.heimatuniversitaet.insert',
        'fh.userData.incomings.sprachkenntnisse.muttersprache',
        'fh.userData.incomings.sprachkenntnisse.muttersprache.insert',
        'fh.userData.incomings.sprachkenntnisse.unterrichtssprache',
        'fh.userData.incomings.sprachkenntnisse.unterrichtssprache.insert',
        'fh.userData.incomings.sprachkenntnisse.toefl',
        'fh.userData.incomings.sprachkenntnisse.toefl.yes',
        'fh.userData.incomings.sprachkenntnisse.toefl.no',
        'fh.userData.incomings.sprachkenntnisse.toeflPunkte',
        'fh.userData.incomings.sprachkenntnisse.toeflPunkte.insert',
        'fh.userData.incomings.sprachkenntnisse.andererTest',
        'fh.userData.incomings.sprachkenntnisse.englischTest',
        'fh.userData.incomings.sprachkenntnisse.englischTest.insert',
        'fh.userData.incomings.sprachkenntnisse.englischTestPunkte',
        'fh.userData.incomings.sprachkenntnisse.englischTestPunkte.insert',
        'fh.userData.incomings.sprachkenntnisse.deutschKenntnisse',
        'fh.userData.incomings.sprachkenntnisse.sprachniveau.english',
        'fh.userData.incomings.sprachkenntnisse.sprachniveau.deutsch',
        'fh.studiengang.bewerbergruppe.code',
        'fh.studiengang.bewerbergruppe.code.insert',
        'fh.userData.incomings.studienjahrUndRichtung',
        'fh.userData.incomings.studienrichtung.insert',
        'fh.userData.incomings.studienrichtung',
        'fh.userData.incomings.studienrichtungAngestrebt.insert',
        'fh.userData.incomings.studienrichtungAngestrebt',
        'fh.userData.incomings.studienjahrSemester',
        'fh.userData.incomings.studienjahrSemester.insert',
        'default.button.next.label',
        'fh.studiengang.bereitsbeworben',
        'fh.kautionszahlung.befreit'
      ]
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.userData.title'];
      });

    },
    setNationalitaet: function (val) {
      this.nationalitaet = val;
    },
    setBundesland: function (val) {
      this.bundesland = val;
    }

  }

}
</script>
