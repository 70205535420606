<!--
Clustering Zugangsvoraussetzungen
https://amsik.github.io/liquor-tree/
-->
<template>

  <div class="selectVorbildungWrapper" v-if="treeData">

    <b-form-input v-model="selected.name" readonly v-if="readonly"></b-form-input>
    <liquor-tree
      v-else
      :data="treeData"
      :options="treeOptions"
      @node:checked="nodechecked"
      @node:unchecked="nodeunchecked"
      ref="tree"
      :state="validation"
    />
    <b-form-invalid-feedback :state="validation">
      {{ invalidMessage }}
    </b-form-invalid-feedback>

  </div>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '../../event-bus';
  axios.defaults.withCredentials = true;
  import LiquorTree from 'liquor-tree'

  export default {

    name: 'select-vorbildung-tree',
    components: {
      LiquorTree
    },
    props: {
      vorbildung: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      staat: {
        type: Object,
        required: true
      },
      /** hole nur Vorbildungen die ein Masterstudium betreffen */
      isForMaster: null,
      readonly: null,
    },
    data () {
      return {
        messages: null,
        /** mögliche Vorbildungen */
        options: null,
        treeData: null,
        treeOptions: {
          multiple: false,
          checkbox: true
        },
        /** zur Prüfung ob Wert erfasst wurde */
        selected: null,
        /** bei Bachelor wird dieser aus dem ausgewählten Studiengang geholt */
        defaultClusterLabel: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', () => {
        this.fetchMessages();
      });

    },
    watch: {

      /** wenn der Staat geändert wurde die Vorbildungen neu laden */
      staat: function () {
        this.init();
      }

    },
    computed: {

      /** prüft ob die Vorbildung eingegeben wurde */
      validation: function () {

        if (!this.selected || this.selected.id == null) {
          return false;
        }
        return true;

      }

    },
    methods: {

      nodeunchecked: function () {

        this.selected = null

      },
      nodechecked: function (obj) {

        console.log('nodechecked', obj)

        const tree = this.$refs.tree.tree;
        // console.log('nodes', tree)

        let checkednodes = tree.checkedNodes;
        for (let i = 0; i < checkednodes.length; i++) {
          if (checkednodes[i].id != obj.id) {
            checkednodes[i].uncheck()
          }
        }

        this.emit(obj)

      },
      init: function () {

        if (this.staat) {

          const bewerbung = this.getBewerbung();
          this.treeData = null;
          const that = this;
          const params = {
            'staat_id':     this.staat.id,
            'isForMaster':  this.isForMaster || false,
            'bewerbung_id': bewerbung,
            'lang':         localStorage.language
          };

          // hole alle Vorbildungen:
          var url  = process.env.VUE_APP_SERVER_URL + '/vorbildung';
          axios.get(url, {
            params: params,
            withCredentials: true
          })
          .then(function (response) {

            that.options = response.data;

            // bei einem AO brauche ich ob Masterstudium oder Bachelor
            that.getBewerbergruppe(function (result) {

              const bewerbergruppe = result;
              if (bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {

                url = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent';
                var params = {
                  bewerbung_id: bewerbung
                }
                axios.get(url, {
                  params: params,
                  withCredentials: true
                }).then( response => {

                  const studiengaenge = response.data;
                  if (
                      studiengaenge &&
                      studiengaenge.hauptStudiengang &&
                      (studiengaenge.hauptStudiengang.studienart == 'MASTER' || studiengaenge.hauptStudiengang.studienart == 'MASTER_LEHRGANG')
                  ) {
                    that.defaultClusterLabel = that.messages['fh.reife.vorbildung.hochschulabschluss']
                  } else {
                    that.defaultClusterLabel = that.messages['fh.reife.vorbildung.hochschulreife']
                  }
                  // baue den Tree zusammen:
                  that.createTree();

                })

              } else {

                // baue den Tree zusammen:
                that.createTree();

              }

            });

          });

        }

      },
      /**
       * erzeugt den Tree/Clustering
       */
      createTree: function () {

        console.log('createTree', this.options);

        let kein_cluster = null;
        let kein_cluster_label = null;
        if (this.defaultClusterLabel) {
          kein_cluster_label = this.defaultClusterLabel
        } else {
          kein_cluster_label = this.isForMaster ?
            this.messages['fh.reife.vorbildung.masterreife'] :
            this.messages['fh.reife.vorbildung.hochschulreife']
        }

        let treedata = [];

        // 1. Durchlauf, bilde die Cluster:
        for (let i = 0; i < this.options.length; i++) {

          // wenn es nur 1 Eintrag gibt, dann kann der gleich ausgewählt werden:
          if (!this.vorbildung && this.options.length == 1) {
            this.selected = this.options[0]
          }

          const item = this.options[i];
          if (item.cluster) {

            // gibt es den cluster schon?
            let exists = false
            for (var j = 0; j < treedata.length; j++) {
              if (treedata[j].text == item.cluster) {
                  exists = true
                  break
              }
            }
            if (!exists) {

              treedata.push({
                text: item.cluster,
                children: [],
                id: this.options[i].id
              })

            }

          } else if (!kein_cluster) {
            // item ohne cluster, erzeuge den default cluster
            kein_cluster = {
              text: kein_cluster_label,
              children: [],
              id: this.options[i].id
            }
            treedata.push(kein_cluster);
          }

        }

        // 2. Durchlauf, setzte die Childs eines Cluster
        for (var i = 0; i < this.options.length; i++) {

          const item = this.options[i];

          if (!item.cluster) {
            item.cluster = kein_cluster_label
          }

          if (item.cluster) {

            // füge dem Cluster als Child hinzu:
            for (var j = 0; j < treedata.length; j++) {

              if (treedata[j].text == item.cluster) {

                const check = this.vorbildung && this.vorbildung.id == this.options[i].id ? true : false
                treedata[j].children.push({
                  text: this.options[i].name,
                  state: { selected: check, checked: check },
                  id: this.options[i].id
                })
                break

              }

            }

          }

        }

        // sortiere die 1. Ebene gleich mal alphabetisch
        const sortStringFunction = function(a, b) {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        }
        treedata.sort(sortStringFunction);
        // sortiere die Tree Childs alphabetisch:
        for (let i = 0; i < treedata.length; i++) {
          if (treedata[i].children && treedata[i].children.length > 0) {
            treedata[i].children.sort(sortStringFunction)
          }
        }
        // 'Matura vor 1992' gehört an das Ende der Liste
        let newlist = []
        let matura = null
        for (let i = 0; i < treedata.length; i++) {
          if (treedata[i].text == 'Matura vor 1992') {
            matura = treedata[i]
          } else {
            newlist.push(treedata[i])
          }
        }
        if (matura) {
          newlist.push(matura)
        }
        treedata = newlist

        // 3. Durchlauf, muss ein Parent expanded werden?
        let expand_id = null;
        if (this.selected && this.selected.id) {
          expand_id = this.selected.id
        } else if (this.vorbildung && this.vorbildung.id) {
          expand_id = this.vorbildung.id
        }
        if (expand_id) {

          for (let i = 0; i < treedata.length; i++) {
            console.log('expandit?', treedata[i].id + ' ' + expand_id)
            if (treedata[i].id == expand_id && treedata[i].children && treedata[i].children.length > 0) {
              treedata[i].state = { expanded: true }
              console.log('expandit', treedata[i].state)
              break
            } else if (treedata[i].children && treedata[i].children.length > 0) {

              for (let j = 0; j < treedata[i].children.length; j++) {
                if (treedata[i].children[j].id == expand_id) {
                  treedata[i].state = { expanded: true }
                  console.log('expandit', treedata[i].state)
                  break
                }
              }

            }

          }

          if (!this.selected) this.selected = this.vorbildung

        }

        // wenn nur ein cluster, dann expanden:
        if (treedata.length == 1) {
          treedata[0].state = { expanded: true }
        }

        this.treeData = treedata;

      },
      /** emit Vorbildung */
      emit: function (val) {

        for (var i = 0; i < this.options.length; i++) {
          if (val.id == this.options[i].id) {
            this.$emit('set-vorbildung', this.options[i])
            this.selected = this.options[i]
            break
          }
        }

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        console.log('vorbildung tree fetchMessages', language);
        this.sprache = language || localStorage.language;

        var messageslist = [
          'fh.reife.vorbildung.hochschulreife',
          'fh.reife.vorbildung.masterreife',
          'fh.reife.vorbildung.hochschulabschluss'
        ];
        const that = this;
        this.getMessages(language, messageslist, function (resp) {
          that.messages = resp;
          that.init();
        });

      },

    }

  }

</script>
<style>

  .has-child > div > i.tree-checkbox {
    display: none;
  }
  .tree-arrow {
    margin-left: 0!important;
  }
  .tree-checkbox.checked, .tree-checkbox.indeterminate {
    background-color: black!important;
    border-color: black!important;
  }
  .tree-node.selected>.tree-content {
    background-color: white !important;
  }
  .tree-node.checked>.tree-content {
    background-color: #e7eef7 !important;
  }

</style>
