<template>

  <div name="userform" id="userform">

    <transition name="fade" mode="out-in">

      <div v-if="messages">

          <check-login></check-login>
          <nav-tabs :active="'Messages'"></nav-tabs>

          <div class="messages-wrapper">

            <h1 class="h2 mt-5">{{ messages['fh.messages.h1'] }}</h1>

            <div v-if="nachrichten && nachrichten.length > 0" class="mt-3">

              <span v-for="nachricht in nachrichten"
                    v-bind:key="nachricht.id">

                <b-overlay :show="showOverlay(nachricht)" rounded="sm" :opacity="0.4">
                  <b-card header-tag="header"
                          footer-tag="footer"
                          class="mb-3">

                    <!-- Header -->
                    <template #header>
                      <div class="row">
                        <div class="col">
                          <h3 class="h6 mb-0">
                            <studiengang-name :admission-process-id="nachricht.admissionProcessId" />
                          </h3>
                        </div>
                        <div class="col text-right">{{ nachricht.originalCreationDate }}</div>
                      </div>
                    </template>

                    <b-card-text v-if="nachricht.read" style="padding:0">
                        <code v-if="showDeContent(nachricht)">{{ nachricht.contentDe }}</code>
                        <code v-if="showEnContent(nachricht)">{{ nachricht.contentEn }}</code>
                    </b-card-text>

                    <!-- Footer -->
                    <template #footer v-if="!nachricht.read">
                        <div class="row">
                          <div class="col">
                            <b-button class="button secondary black"
                                      @click="setGelesen(nachricht)"
                                      v-if="!nachricht.readReceiptDate">
                              <b-icon icon="eye"/>&nbsp;{{ messages['fh.messages.setReaded'] }}
                            </b-button>
                          </div>
                        </div>
                    </template>

                  </b-card>
                  <template #overlay>
                    <div class="text-center" v-b-tooltip.hover :title="messages['fh.messages.show']">
                      <b-button
                        ref="cancel"
                        size="sm"
                        class="button secondary black"
                        @click="hideOverlay(nachricht)">
                          <b-icon icon="eye" />&nbsp;{{ getGelesen(nachricht) }}
                      </b-button>
                    </div>
                  </template>
                </b-overlay>

              </span>

            </div>
            <!-- Keine Nachrichten vorhanden -->
            <div v-else-if="nachrichten && nachrichten.length < 1" class="mt-5">
              <b-alert show>{{ messages['fh.messages.noMessages'] }}</b-alert>
            </div>
            <!-- Nachrichten werden geladen -->
            <div v-else>
              <div class="lds-ripple"><div></div><div></div></div>
            </div>

          </div>

      </div>

    </transition>

  </div>

</template>
<style scoped>

</style>
<script>

  import NavTabs                from './layout/NavTabs.vue';
  import CheckLogin             from './login/CheckLogin.vue';
  import axios                  from 'axios';
  // Import the EventBus.
  import { EventBus }           from '../event-bus.js';
  axios.defaults.withCredentials = true;
  import StudiengangName        from './studiengang/StudiengangName';

  export default {

    name: 'Messages',
    components: {
      NavTabs,
      CheckLogin,
      StudiengangName
    },
    data () {
      return {
        /** i18n Messages */
        messages: null,
        /** die Nachrichten an den User */
        nachrichten: null,
        /** derzeit verwendete Sprache */
        sprache: null
      }
    },
    mounted: function () {

      this.init();

    },
    methods: {

      hideOverlay: function (nachricht) {

        // Nachricht anzeigen:
        nachricht.read        = true;
        nachricht.showOverlay = false;
        // das array neu erzeugen, sonst wird das nicht verändert
        var newlist = [];
        for (var i = 0; i < this.nachrichten.length; i++) {

          newlist.push(this.nachrichten[i]);

        }
        this.nachrichten = newlist;

      },
      /** Overlay über Nachricht anzeigen oder nichgt */
      showOverlay: function (nachricht) {

        if (nachricht.read) return false;

        if (nachricht.showOverlay === false) return false;

        return nachricht.readReceiptDate ? true : false;

      },
      /** Liefert den "gelesen" String */
      getGelesen: function (nachricht) {

        return this.messages['fh.messages.read'].replace('{0}', nachricht.readReceiptDate);

      },
      /**
       * Nachricht als gelesen markieren
       */
      setGelesen: function (nachricht) {

        // Nachricht anzeigen:
        nachricht.read = true;

        this.showSpinner();
        var that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/messages/setGelesen';
        var params = {
          // nachricht: JSON.stringify(nachricht)
          oracle_id: nachricht.id
        }
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        }).then(function (response) {

          nachricht.readReceiptDate = response.data.readReceiptDate;
          that.hideSpinner();

        }).catch(error => {
          console.log(error.response.data.error)
          that.hideSpinner()
        })

      },
      /**
       * zeige den Nachrichtentext auf deutsch
       */
      showDeContent: function (nachricht) {

        if (this.sprache === 'de' && nachricht.contentDe) {
          return nachricht.contentDe;
        } else if (this.sprache === 'de') {
          return nachricht.contentEn;
        }

      },
      /**
       * zeige den Nachrichtentext auf deutsch
       */
      showEnContent: function (nachricht) {

        if (this.sprache !== 'de' && nachricht.contentEn) {
          return nachricht.contentEn;
        } else if (this.sprache !== 'de') {
          return nachricht.contentDe;
        }

      },
      init: function() {

        this.fetchMessages();
        // Listen for the set-language event on EventBus
        EventBus.$on('set-language', lang => {
          this.fetchMessages(lang);
        });

        var that = this;
        // hole bestehende Bewerbungen des Users -> zuerst die ungelesenen
        const url = process.env.VUE_APP_SERVER_URL + '/messages/getMessages';
        var params = {
          unreaded: true
        }
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        }).then(function (response) {

          that.nachrichten = response.data;

          // dann die bereits gelesenen
          params = {
            unreaded: false
          }
          axios({
              method: 'post',
              url: url,
              params: params,
              withCredentials: true
          }).then(function (responseReaded) {

            if (responseReaded.data) {
              for (var i = 0; i < responseReaded.data.length; i++) {
                that.nachrichten.push(responseReaded.data[i]);
              }
            }

          });

        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        this.sprache = language || localStorage.language;

        var messages = [
          'fh.messages.h1',
          'fh.messages.noMessages',
          'fh.messages.read',
          'fh.messages.show',
          'fh.messages.setReaded'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
          // Seiten Titel setzen:
          document.title = that.messages['fh.messages.h1'];
        });

      }

    }

  }
</script>
